<template>
    <div>
        <el-dialog :title="`Order Export`" :visible.sync="shouldShow" @close="closeEvent" width="40% !important">
            <eden-file-export :data="exportData" @exported="closeEvent" @cancelled="closeEvent">
                <div class="mb-3">Select the period you will like to export?</div>
                <el-form ref="form" :model="form" label-position="top">
                    <el-row type="flex" class="is-flex-wrap" :gutter="15">
                        <el-col :md="12" :lg="12">
                            <el-form-item label="Start Date" prop="start_date" :rules="validateField()">
                                <el-date-picker v-model="form.start_date" :clearable="false" type="date"
                                    format="dd MMM, yyyy" value-format="yyyy-MM-dd" placeholder="Start Date"
                                    prefix-icon="eden-icon--calendar" />
                            </el-form-item>
                        </el-col>
                        <el-col :md="12" :lg="12">
                            <el-form-item label="End Date" prop="end_date" :rules="validateField()">
                                <el-date-picker v-model="form.end_date" :clearable="false" type="date" format="dd MMM, yyyy"
                                    value-format="yyyy-MM-dd" placeholder="End Date" prefix-icon="eden-icon--calendar" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="24">
                            <el-form-item label="Pages" prop="page" :rules="validateField()">
                                <el-select v-model="form.page" placeholder="Select period">
                                    <el-option v-for="(value, key, i) in pages" :key="i" :label="value" :value="key" />
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <template v-if="form.page === 'custom'">
                        <el-row type="flex" class="is-flex-wrap" :gutter="15">
                            <el-col :md="12" :lg="12">
                                <el-form-item label="From" prop="from_page" :rules="validateField()">
                                    <el-input type="number" v-model="form.from_page" />
                                </el-form-item>
                            </el-col>
                            <el-col :md="12" :lg="12">
                                <el-form-item label="To (optional)" prop="to_page">
                                    <el-input type="number" v-model="form.to_page" />
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </template>
                    <el-row>
                        <el-col :span="24">
                            <el-form-item label="Select file format">
                                <el-radio-group v-model="form.format">
                                    <el-radio label="pdf">PDF</el-radio>
                                    <el-radio label="csv">CSV</el-radio>
                                    <el-radio label="xls">Excel</el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
                <div class="is-flex is-justify-end">
                    <el-button type="primary" :loading="loading || exporting" @click="exportOrders"
                        :disabled="!form.format">Export</el-button>
                </div>
            </eden-file-export>
        </el-dialog>
    </div>
</template>
  
<script>
import details from "@/requests/vendors/details.js";
export default {
    name: "OrderExport",
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        orders: {
            type: Array,
            default: () => [],
        },
        dataTime: {
            type: [Date, String],
            default: "",
        },
    },

    data() {
        return {
            loading: false,
            formats: {
                pdf: "PDF",
                csv: "CSV",
                xls: "Excel",
            },
            form: {
                page: "",
                from_page: "",
                to_page: "",
                start_date: "",
                end_date: "",
                one_date: "",
                format: "pdf",
            },
            pages: {
                all: "All Pages",
                custom: "Custom Pages",
            },
            exportData: {},
            exporting: false,
        };
    },

    computed: {
        shouldShow: {
            get() {
                return this.show;
            },
            set(value) {
                this.$emit("update:show", value);
            },
        },
    },
    watch: {
        exporting() {
            if (!this.exporting) {
                this.closeEvent();
            }
        },
    },
    methods: {
        closeEvent() {
            this.form = {
                page: "",
                from_page: "",
                to_page: "",
                start_date: "",
                end_date: "",
                one_date: "",
            };
            this.exporting = false;
            this.shouldShow = false;
        },

        exportOrders() {
            if (this.$refs.form && typeof this.$refs.form.validate === "function") {
                this.$refs.form.validate(async (valid) => {
                    if (!valid) {
                        return false;
                    }
                    this.exporting = true;
                    let payload = {
                        vendor_id: this.$route.params.id,
                        delivery_window: "all",
                        status: "all",
                        start_date: this.form.start_date,
                        end_date: this.form.end_date,
                        export_format: this.form.format,
                        from_page: this.form.from_page,
                        to_page: this.form.to_page ? this.form.to_page : this.form.from_page,
                        export_all_pages: this.form.page === "custom" ? false : true,
                        ...this.exportData
                    }
                    console.log(payload)
                    details
                    .export(payload)
                    .then((response) => {
                        this.$message.success(response.data.message);
                        this.exporting = false;
                    })
                    .catch((error) => {
                        const errorMessage = error.response.data;
                        if (errorMessage.errors) {
                            const errorKeys = Object.keys(errorMessage.errors);
                            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
                        } else {
                            this.$message.error(errorMessage.message);
                        }
                    });
                });
            } else {
                console.error("Form validation method is not available.");
            }
        },
    },
};
</script>
<style lang="scss" scoped></style>
  