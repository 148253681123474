<template>
  <div>
    <eden-table-actions :title="'Orders'" :show-search="false">
      <template slot="actions">
        <el-button type="plain" class="mr-2 text-primary" @click="viewOrders">
          View all Orders
        </el-button>
      </template>
    </eden-table-actions>
    <eden-loader v-if="loading" />
    <template v-else>
      <eden-filter-items
        v-if="filterParams.status"
        :params="filterParams.paramsLabel"
        @clear-filter="clearFilter"
        @clear-filters="clearFilters"
      />
      <el-table :data="vendorOrders.data">
        <el-table-column width="150">
          <template slot="header">
            <div class="table--header">
              <span>Order ID</span>
            </div>
          </template>
          <template slot-scope="scope">
            <router-link
              :to="{
                name: 'ordersAndSchedule.details',
                params: { id: scope.row.uuid },
              }"
            >
              <span class="font-sm text-primary">
                #{{ scope.row.order_number }}</span
              ></router-link
            >
          </template>
        </el-table-column>
        <el-table-column>
          <template slot="header">
            <span class="eden-table-header"> Order </span>
          </template>
          <template slot-scope="scope">
            <span class="font-sm">
              <div class="wrapper-overflow mr-2">
                {{ customerOrder(scope.row.order_items) }}
              </div>
            </span>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot="header">
            <div class="table--header">
              <span>Delivery Area</span>
            </div>
          </template>
          <template slot-scope="scope">
            <span class="font-sm table--status">
              {{ scope.row.delivery_address ?? "-" }}
            </span>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot="header">
            <div class="table--header">
              <span>Amount</span>
            </div>
          </template>
          <template slot-scope="scope">
            <span>{{ formatPrice(scope.row.total_after_discount) }}</span>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot="header">
            <div class="table--header">
              <span>Status</span>
            </div>
          </template>
          <template slot-scope="scope">
            <el-tag :type="setType(scope.row.status)">{{
              scope.row.status
            }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot="header">
            <div class="table--header">
              <span>Date</span>
            </div>
          </template>
          <template slot-scope="scope">
            {{ formatDate(scope.row.delivery_date, "do m, y") }}
          </template>
        </el-table-column>
      </el-table>
    </template>
    <vendor-orders v-if="showOrdersView" />
  </div>
</template>

<script>
import details from "@/requests/vendors/details.js";
// import VendorOrders from './VendorOrders.vue'
export default {
  props: {
    tab: {
      default: "",
    },
  },
  data() {
    return {
      loading: false,
      page: 1,
      pageData: [],
      vendorOrders: {},
      exporting: false,
      showPagination: true,
      sort: {
        property: "name",
        direction: "asc",
      },
      showOrdersView: false,
      filterParams: {
        clear: false,
        status: false,
        params: {},
        paramsLabel: {},
      },
      showExportCustomers: false,
      pageDataTime: "",
      exportData: [],
    };
  },
  watch: {
    tab(newTab, oldTab) {
      // this will be called anytime `this.tab` changes
      console.log("Tab value changed from:", oldTab, "to:", newTab);
      this.tab = newTab;
      this.getOrders();
    },
    page() {
      this.getOrders();
    },
  },
  computed: {
    title() {
      return `${this.vendorOrders?.total} Orders`;
    },
  },
  created() {
    this.getOrders();
  },
  methods: {
    viewOrders() {
      this.$router.push({
        name: "vendor.orders",
        params: { id: this.$route.params.id },
      });
    },
    searchLocationAreas(query) {
      if (query === "") {
        return;
      }
    },
    customerOrder(orderItems) {
      const MAX_LENGTH = 10;
      if (!Array.isArray(orderItems)) {
        return "";
      }
      let names = orderItems.map(
        (item) =>
          `${item.quantity} ${this.formatMealName(item.main_item.name)}`,
      );
      let str =
        names.length > 1
          ? `${names.slice(0, -1).join(", ")} and ${names[names.length - 1]}`
          : names[0];

      if (str && str.length > MAX_LENGTH) {
        str = str.substring(0, MAX_LENGTH - 3) + "...";
      }

      return str;
    },
    filter({ params, paramsLabel }) {
      this.loading = true;
      const stringedParams = this.sortObjectAsParams(params);

      details
        .ordersFilter(this.$route.params.id, stringedParams)
        .then((response) => {
          this.filterParams = {
            status: true,
            params: params,
            paramsLabel: paramsLabel,
          };
          this.vendorOrders = response.data.data;
          // this.showPagination = false;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    clearFilter(key) {
      delete this.filterParams.params[key];
      delete this.filterParams.paramsLabel[key];

      const keys = this.filterParams.params;

      if (Object.keys(keys).length) {
        this.filter({
          params: this.filterParams.params,
          paramsLabel: this.filterParams.paramsLabel,
        });
      } else {
        // this.setPageData();
      }
    },
    clearFilters() {
      this.$router.push({ name: "vendors.index" });
      // this.setPageData();
    },
    getOrders() {
      this.loading = true;
      const params = `filters[delivery_window]=${this.tab}`;
      details
        .orders(this.$route.params.id, params)
        .then((response) => {
          if (response.data.status) {
            this.loading = false;
            this.vendorOrders = response.data.data;
          }
        })
        .catch((err) => {
          this.$message.error(err.response.data.message);
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
