<template>
  <eden-filter :width="270" :properties.sync="properties" :disabled="disabled" :loading="loading" @open="getData"
    @reset="reset" @filter="filter" />
</template>
  
<script>
import * as actions from "@/store/action-types";
export default {
  name: "OrderFilter",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    clear: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      properties: {
        locationarea: {
          key: "filters[delivery_area_id]",
          label: "Delivery Area",
          type: "list",
          options: [],
          value: [],
          searchable: true,
        },
        status: {
          key: "filters[status]",
          label: "Status",
          type: "list",
          options: [
            {
              label: "Pending",
              value: "pending",
            },
            {
              label: "Accepted",
              value: "accepted",
            },
            {
              label: "Cancelled",
              value: "cancelled",
            },
          ],
          value: [],
          searchable: true,
        },
      },
    };
  },
  computed: {
    locationareas() {
      return this.$store.getters.location_areas_list;
    },
  },
  watch: {
    clear() {
      if (this.clear) {
        this.reset();
      }
    },
  },
  methods: {
    getData() {
      this.getGardeners()
    },
    getGardeners() {
      if (this.properties.locationarea.options.length) {
        this.loading = false;
        return;
      }
      this.locationareas.length
        ? this.setLocationAreasOptions()
        : this.$store.dispatch(actions.GET_LOCATION_AREAS_LIST).then(() => {
          this.setLocationAreasOptions();
        });
    },
    setLocationAreasOptions() {
      this.properties.locationarea.options = this.locationareas.map((locationarea) => {
        return {
          label: this.formatText(locationarea.name),
          value: locationarea.id,
        };
      });
      this.loading = false;
    },
    reset() {
      Object.keys(this.properties).forEach((key) => {
        this.properties[key].value = [];
      });
    },
    filter() {
      let params = {
        params: {},
        paramsLabel: {},
      };

      Object.keys(this.properties).forEach((property) => {
        const { key, type, value } = this.properties[property];
        if (value && value.length) {
          switch (type) {
            case "list":
              params.params[key] = value[0];
              params.paramsLabel[key] = this.properties[property];
              break;
            case "list-multiple":
              params.params[key] = value;
              params.paramsLabel[key] = this.properties[property];
              break;
            case "daterange":
              params.params[key[0]] = this.properties[property].value[0];
              params.params[key[1]] = this.properties[property].value[1];
              params.paramsLabel[key] = this.properties[property];
              break;
            default:
              break;
          }
        }
      });
      if (Object.keys(params.params).length) {
        this.$emit("filter", {
          params: params.params,
          paramsLabel: params.paramsLabel,
        });
      } else {
        this.$message.info("Filtering requires at least one property");
      }
    },
  },
};
</script>
  
<style lang="scss" scoped></style>
  