<template>
  <div class="">
    <el-tabs v-model="tab" @tab-click="updateRoute">
      <el-tab-pane
        v-for="eachTab in tabs"
        :label="eachTab.label"
        :name="eachTab.name"
        :key="eachTab.name"
      >
        <div v-if="$route.path.includes('/vendor-orders')">
          <component :is="'VendorDetailOrderView'" :tab="tab" />
        </div>
        <div v-else>
          <component :is="'VendorDetailsOrder'" :tab="tab" />
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import VendorDetailsOrder from "./vendor-details-orders.vue";
import VendorDetailOrderView from "./VendorDetailOrderView.vue";
export default {
  name: "OrderTypes",
  components: {
    VendorDetailsOrder,
    VendorDetailOrderView,
  },
  props: {
    data: {
      default: {},
    },
  },
  data() {
    return {
      tab: "on_demand",
    };
  },

  computed: {
    tabs() {
      // if (this.data.delivery_window === 'both') {
      return [
        {
          name: "on_demand",
          label: "Instant delivery",
        },
        {
          name: "pre_order",
          label: "Pre-orders",
        },
      ];
      // } else if (this.data.delivery_window === 'pre_order') {
      //     return [
      //         {
      //             name: "pre_order",
      //             label: "Pre-orders",
      //         },
      //     ];
      // } else if (this.data.delivery_window === 'on_demand') {
      //     return [
      //         {
      //             name: "on_demand",
      //             label: "Instant delivery",
      //         },
      //     ];
      // } else {
      //     return [];
      // }
    },
  },

  methods: {
    updateRoute(tab) {
      const currentTab = this.$route.query.order_type;

      if (currentTab === this.order_type) {
        return false;
      }
      this.$router.push({
        name: "vendors.details",
        query: { ...this.$route.query, order_type: tab.name },
      });
    },
  },
};
</script>
